import React, { useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter as Router, Routes, Route, useSearchParams, useLocation, useNavigate,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import {
  getResponsesById,
} from './actions/responses';
import Loader from './components/Loader';
import withModalConfirm from './components/withModalConfirm';

import questions from './assets/data/questions.json';
import Steps from './pages/steps';
import End from './pages/end';

const defaultContext: {
  initUserResults: (Record<string, any>) | null,
  userInfo: (Record<string, any>) | null,
} = {
  initUserResults: null,
  userInfo: null,
};

export const ContextResponses = React.createContext(defaultContext);

interface LayoutProps {
  confirm: (
    message: string,
    action: () => void,
    cancel?: (() => void) | null
  ) => void;
}

const Layout = withModalConfirm((
  { confirm } : LayoutProps,
) => {
  const [params, setParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [initUserResults, setInitUserResults] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<(Record<string, any> | null)>(null);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const getIsCompleted = (responses: any) => {
    const questionsCount = questions.filter((question) => /^Q/.test(question.__EMPTY_1))?.length || 0;
    const answersCount = Object.keys(responses).length;
    return questionsCount === answersCount;
  };

  const getIndexStep = useCallback(() => {
    let index: string | number | null = params.get('step');
    if (index) index = parseInt(index, 10);
    if (!index || Number.isNaN(index)) index = 1;
    if (!questions[index as number] && index !== 89) index = 1;
    return index as number;
  }, [params]);

  useEffect(() => {
    const index = getIndexStep();
    if (pathname === '/' && index === 1) {
      localStorage.removeItem('id');
    }

    const id = localStorage.getItem('id');
    if (!id) {
      const newParams = new URLSearchParams();
      setParams(newParams);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getResponsesById(id).then((res) => {
      if (!res) {
        localStorage.removeItem('id');
      } else if (res?.responses) {
        setInitUserResults(res.responses);
        setUserInfo(res.userInfo);
        const isCompleted = getIsCompleted(res.responses);
        if (pathname === '/end' && !isCompleted) {
          localStorage.removeItem('id');
          navigate('/');
        }
        getIsCompleted(res.responses);
      }
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      localStorage.removeItem('id');
    });
  }, []);

  useEffect(() => {
    if (!initUserResults || pathname !== '/') {
      setIsLoading(false);
      return;
    }
    const index = getIndexStep();

    //* Get previous answer
    let previousAnswerId: any = null;
    let previousAnswerStep: any = null;
    let i = index - 1;
    while (questions[i]) {
      const id = questions[i].__EMPTY_1 || '';
      if (/^Q/.test(id) && initUserResults[id] && initUserResults[id].length > 0) {
        previousAnswerId = questions[i].__EMPTY_1;
        previousAnswerStep = i;
        break;
      }
      i -= 1;
    }

    //* Get previous question
    let previousQuestionId: any = null;
    let j = (index as number) - 1;
    while (questions[j]) {
      const id = questions[j].__EMPTY_1 || '';
      if (/^Q/.test(id)) {
        previousQuestionId = questions[j].__EMPTY_1;
        break;
      }
      j -= 1;
    }

    if (previousQuestionId !== previousAnswerId) {
      confirm(
        'Voulez vous reprendre où vous en êtiez ou recommencer ?',
        () => {
          // * Handle 'continue'
          const updateResult = { ...initUserResults };
          delete updateResult[previousAnswerId];
          const id = localStorage.getItem('id');
          if (!id) return;
          const newParams = new URLSearchParams();
          newParams.set('step', previousAnswerStep);
          setParams(newParams);
        },
        () => {
          // * Handle 'restart'
          const newParams = new URLSearchParams();
          setParams(newParams);
          localStorage.removeItem('id');
          setInitUserResults(null);
        },
      );
    }
  }, [initUserResults]);

  useEffect(() => {
    const indexStep = getIndexStep();
    if (indexStep === 89) navigate('/end');
  }, [params]);

  return (
    <ContextResponses.Provider value={{ initUserResults, userInfo }} >
      <AnimatePresence >
        <Routes location={location} key={pathname}>
          {isLoading && <Loader />}
          {(typeof isLoading === 'boolean' && !isLoading) && (
            <>
              <Route path="*" element={<Steps />} />
              <Route path="end" element={<End />} />
            </>
          )}
        </Routes>
      </AnimatePresence>
    </ContextResponses.Provider>
  );
});

const App = () => (
    <div className="App">
      <Router>
        <Routes >
          <Route
            path="*"
            element={(
              <Layout />
            )}
          />
        </Routes>
      </Router>
    </div>
);

export default App;
