const images: Record<number | string, string> = {
  default: 'gary-3.png',
  6: 'gary-1.png',
  8: 'gary-2.png',
  10: 'gary-3.png',
  12: 'gary-2.png',
  15: 'gary-1.png',
};

export default images;
