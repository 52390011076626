const getResponsesById = async (id: string) => {
  const config = {
    method: 'GET',
  };
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/responses/${id}`, config);
    if (res.status === 204) return null;
    if (res.status === 500) {
      const data = await res.json();
      throw new Error(data.error);
    } else {
      const data = await res.json();
      return data;
    }
  } catch (err: any) {
    throw new Error(err);
  }
};

const createResponses = async (responses: any) => {
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(responses),
  };
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/responses`, config);
    const data = await res.json();
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const updateResponses = async (responses: any, id: string) => {
  const config = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(responses),
  };
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/responses/${id}`, config);
    const data = await res.json();
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export {
  getResponsesById,
  createResponses,
  updateResponses,
};
