import React, {
  useRef, useState, useEffect, useMemo, useCallback, useContext,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { createResponses, updateResponses } from '../../actions/responses';
import { ContextResponses } from '../../App';
import questions from '../../assets/data/questions.json';
import Thread from '../../components/Thread';
import styles from './steps.module.scss';
import Character from '../../components/Character';
import Visuals from '../../components/Visuals';

const Steps = () => {
  const { initUserResults } = useContext(ContextResponses);
  const previousIndexStep = useRef<number | null>();
  const threadRef = useRef<any>();
  const [step, setStep] = useState<null | any>();
  const [params, setParams] = useSearchParams();
  const [userResults, setUserResults] = useState<Record<string, string[]>>(initUserResults || {});

  useEffect(() => {
    setUserResults(initUserResults || {});
  }, [initUserResults]);

  const currentStep = useMemo(() => {
    let index: string | number | null = params.get('step');
    if (index) index = parseInt(index, 10);
    if (!index || Number.isNaN(index)) index = 1;
    if (!questions[index as number]) index = 1;

    return index as number;
  }, [params, userResults]);

  const id = useMemo(() => step?.__EMPTY_1, [step]);
  const isQuestion = useMemo(() => /^Q/.test(id), [id]);
  const text = useMemo(() => step?.__EMPTY_5, [step]);
  const answersType = useMemo(() => {
    if (!isQuestion) return null;
    let type = 'multiChoice';
    if (step?.__EMPTY_6 === 'Choix multiple') {
      type = 'uniqChoice';
    } else if (step?.__EMPTY_6 === 'Emoticones') {
      type = 'emotes';
    } else if (step?.__EMPTY_6 === 'Champ texte') {
      type = 'text';
    }

    // * Handle Type with free text
    if (type !== 'text' && /Champ text/.test(step?.__EMPTY_6)) {
      type += 'AndText';
    }
    return type;
  }, [step, isQuestion]);

  const answers = useMemo(() => {
    let array: {
      id: string,
      text: string,
      customAnswer?: boolean,
    }[] = [];
    if (!isQuestion) return array;

    if (step?.__EMPTY_7) array.push({ id: step?.__EMPTY_1, text: step?.__EMPTY_7 });
    if (step?.__EMPTY_8) array.push({ id: step?.__EMPTY_1, text: step?.__EMPTY_8 });
    if (step?.__EMPTY_9) array.push({ id: step?.__EMPTY_1, text: step?.__EMPTY_9 });
    if (step?.__EMPTY_10) array.push({ id: step?.__EMPTY_1, text: step?.__EMPTY_10 });

    if (answersType === 'text') {
      array = [{
        id: step?.__EMPTY_1,
        text: '',
        customAnswer: true,
      }];
      return array;
    }

    if (answersType === 'multiChoiceAndText') {
      array.push({
        id: step?.__EMPTY_1,
        text: '',
        customAnswer: true,
      });
    }

    return array;
  }, [step]);

  const handleUserResults = async (responses: Record<string, string[]>) => {
    if (Object.entries(responses).length === 0) return;
    const responsesId = localStorage.getItem('id');
    if (responsesId) {
      updateResponses({ responses }, responsesId);
    } else {
      const res = await createResponses({ responses });
      if (res) localStorage.setItem('id', res._id);
    }
    setUserResults(responses);
  };

  // Handle navigation between step
  useEffect(() => {
    // Handle prev navigation
    if (previousIndexStep.current && previousIndexStep.current > currentStep) {
      threadRef.current.resetContent();
    }

    // Reset result for the current step
    // if (previousIndexStep.current
    //   && (previousIndexStep.current < currentStep || previousIndexStep.current > currentStep)) {
    //   const currentIdStep = questions[currentStep].__EMPTY_1;
    //   if (currentIdStep && userResults[currentIdStep]) {
    //     const updatedState = { ...userResults };
    //     delete updatedState[currentIdStep];
    //     handleUserResults(updatedState);
    //   }
    // }
    previousIndexStep.current = currentStep;
  }, [currentStep, userResults]);

  useEffect(() => {
    setStep(questions[currentStep]);
  }, [currentStep]);

  const handleNextStep = useCallback((userTextAnswer?: string | null) => {
    const updateParams = new URLSearchParams(params);
    updateParams.set('step', `${currentStep + 1}`);
    if ((answersType === 'text' || answersType === 'multiChoiceAndText') && userTextAnswer) {
      handleUserResults({
        ...userResults,
        [step.__EMPTY_1]: [userTextAnswer],
      });
    }

    setParams(updateParams);
  }, [currentStep, step, answersType, userResults]);

  const handleChangeResults = (
    answer: string,
    // index: string,
  ) => {
    // const currentResults : any = { ...results };
    const updatedUserResults = { ...userResults };
    if (answersType !== 'uniqChoice' && answersType !== 'emotes' && updatedUserResults[step.__EMPTY_1] && updatedUserResults[step.__EMPTY_1].includes(answer)) {
      updatedUserResults[step.__EMPTY_1] = updatedUserResults[step.__EMPTY_1]
        .filter((d: string) => d !== answer);

      if (updatedUserResults[step.__EMPTY_1].length === 0) {
        delete updatedUserResults[step.__EMPTY_1];
      }
    } else if (answersType !== 'uniqChoice' && answersType !== 'emotes' && updatedUserResults[step.__EMPTY_1]
      && !updatedUserResults[step.__EMPTY_1].includes(answer)) {
      const isCustomAnswer = !answers
        .find((d: any) => updatedUserResults[step.__EMPTY_1].includes(d.text));

      // If there is a custom response
      // we create a new array with the incoming answer
      if (isCustomAnswer) {
        updatedUserResults[step.__EMPTY_1] = [answer];
      } else {
        updatedUserResults[step.__EMPTY_1].push(answer);
      }

      // Suppression de la réponse custom si choix parmis les autres réponses
      // Si j'ai une réponse présente est-elle une custom ?
      // Si oui je la supprime
      // Si non j'ajoute la réponse
    } else if (answersType === 'uniqChoice' || answersType === 'emotes' || !updatedUserResults[step.__EMPTY_1]) {
      updatedUserResults[step.__EMPTY_1] = [answer];
    }

    handleUserResults(updatedUserResults);

    // const relationships = step['Curseur d\'aspiration']?
    // .split('\n')?.find(((r: string) => r.includes(index)))?.split(':')[1];
    // if (Math.round(relationships) > 0) {
    //   currentResults.relationships += Math.round(relationships);
    // }

    // const services = step.__EMPTY_12?.split('\n')?
    // .find(((r: string) => r.includes(index)))?.split(':')[1];
    // if (Math.round(services) > 0) {
    //   currentResults.services += Math.round(services);
    // }
    // const mutualAid = step.__EMPTY_13?.split('\n')?
    // .find(((r: string) => r.includes(index)))?.split(':')[1];
    // if (Math.round(mutualAid) > 0) {
    //   currentResults.mutualAid += Math.round(mutualAid);
    // }
    // const foods = step.__EMPTY_14?.split('\n')?
    // .find(((r: string) => r.includes(index)))?.split(':')[1];
    // if (Math.round(foods) > 0) {
    //   currentResults.foods += Math.round(foods);
    // }
    // const health = step.__EMPTY_15?.split('\n')?
    // .find(((r: string) => r.includes(index)))?.split(':')[1];
    // if (Math.round(health) > 0) {
    //   currentResults.health += Math.round(health);
    // }
    // const territoire = step.__EMPTY_16?.split('\n')?
    // .find(((r: string) => r.includes(index)))?.split(':')[1];
    // if (Math.round(territoire) > 0) {
    //   currentResults.territoire += Math.round(territoire);
    // }

    // setResults(currentResults);
    if (answersType === 'uniqChoice' || answersType === 'emotes') {
      handleNextStep();
    }
  };

  return (
    <motion.div
      className={styles.steps}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className={styles.gsm}>
        <div className={styles.cam} />
        <div className={styles.header} >
          <div className={styles.profilePicture} />
          <div>
            <h2>GARY</h2>
            <p>En train d&apos;écrire...</p>
          </div>
        </div>
        <Visuals
          className={styles.mobileVisuals}
          userResults={userResults}
        />
        <Character className={styles.character} />
        {id && (
          <Thread
            ref={threadRef}
            stepId={step.__EMPTY_1}
            text={text}
            userResults={userResults}
            answers={answers}
            answersType={answersType}
            handleChangeResults={handleChangeResults}
            handleNextStep={handleNextStep}
          />
        )}
      </div>
      <Visuals
        className={styles.desktopVisuals}
        userResults={userResults}
      />
    </motion.div>
  );
};

export default Steps;
