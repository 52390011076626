import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

type Props = {
  children: React.ReactNode;
};

interface IPendingFunction {
  message: string | null;
  action: (() => void) | null;
  cancel?: (() => void) | null;
}

const Modal = ({ children }: Props) => {
  const modalRoot = document.getElementById('modal');
  const [pendingFunction, setPendingFunction] = useState < IPendingFunction >({
    message: null,
    action: null,
    cancel: null,
  });

  const childrenWithProps = () => React.Children.map(children, (child: React.ReactNode) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        confirm: (message: any, action: () => void, cancel: () => void) => {
          setPendingFunction({
            message,
            action,
            cancel,
          });
        },
      });
    }
    return child;
  });

  const confirmAction = (bool: Boolean) => {
    if (bool && pendingFunction.action) pendingFunction.action();
    else if (!bool && pendingFunction.cancel) pendingFunction.cancel();
    setPendingFunction({
      action: null,
      cancel: null,
      message: null,
    });
  };

  return (
    <>
      {modalRoot && pendingFunction.action && pendingFunction.message && (
        <>
          {createPortal(
            <>
              <div className={styles['overlay-modal']} />
              <div className={styles.modal}>
                <p>{pendingFunction.message}</p>
                <div className={styles['container-btn']}>
                  <button onClick={() => confirmAction(true)}>
                    <span>Reprendre</span>
                  </button>
                  <button onClick={() => confirmAction(false)}>
                    <span>Recommencer</span>
                  </button>
                </div>
              </div>
            </>,
            modalRoot,
          )}
        </>
      )}
      {childrenWithProps()}
    </>
  );
};

const withModalConfirm = (Component: React.ComponentType<any>) => ({
  ...props
}) => (
  <Modal>
    <Component {...props} />
  </Modal>
);

export default withModalConfirm;
