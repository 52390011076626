/* eslint-disable react/no-unescaped-entities */
import React, {
  useState, useEffect, useContext, useCallback, useMemo,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { BiDownArrowAlt } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import { MdOutlineClose } from 'react-icons/md';
import { ContextResponses } from '../../App';
import { updateResponses } from '../../actions/responses';
import withModalStudy from '../../components/withModalStudy';
import styles from './end.module.scss';

interface EndProps {
  selectStudy: (
    action: (study: string) => void,
  ) => void;
}

const End = ({ selectStudy }: EndProps) => {
  const { userInfo } : { userInfo: (Record<string, any> | null) } = useContext(ContextResponses);
  const navigate = useNavigate();
  const [spouse, setSpouse] = useState<boolean | null>();
  const [gender, setGender] = useState('');
  const [studies, setStudies] = useState('');
  const [caringExperience, setCaringExperience] = useState<boolean | null>();
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [step, setStep] = useState(1);

  useEffect(() => {
    const id = localStorage.getItem('id');
    if (!id) {
      navigate('/');
    }
  }, []);

  const validEmail = useMemo(() => {
    if (!email) return null;
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }, [email]);

  const submit = useCallback(() => {
    if (!validEmail && email) return;
    const id = localStorage.getItem('id');
    if (!id) return;
    const data = {
      email,
      comment,
      spouse,
      gender,
      caringExperience,
      studies,
    };
    updateResponses({ userInfo: data }, id).then(() => {
      navigate('/');
      localStorage.removeItem('id');
    });
  }, [
    email,
    comment,
    spouse,
    gender,
    caringExperience,
    studies,
    validEmail,
  ]);

  // Init value
  useEffect(() => {
    if (userInfo?.email) setEmail(userInfo?.email);
    if (userInfo?.comment) setComment(userInfo?.comment);
    if (typeof userInfo?.spouse === 'boolean') setSpouse(userInfo?.spouse);
    if (typeof userInfo?.caringExperience === 'boolean') setCaringExperience(userInfo?.caringExperience);
    if (userInfo?.gender) setGender(userInfo?.gender);
    if (userInfo?.studies) setStudies(userInfo?.studies);
  }, [userInfo]);

  const toggleModalStudy = useCallback(() => {
    const id = localStorage.getItem('id');
    if (!id) return;
    selectStudy((answer) => {
      const data = {
        email,
        comment,
        spouse,
        gender,
        caringExperience,
        studies: answer,
      };
      updateResponses({ userInfo: data }, id).then(() => {
        setStudies(answer);
      });
    });
  }, [
    email,
    comment,
    spouse,
    gender,
    caringExperience,
  ]);

  const handleChangeChoice = (key: string, value: any) => {
    const id = localStorage.getItem('id');
    if (!id) return;
    const data = {
      spouse, gender, caringExperience, [key]: value,
    };
    updateResponses({ userInfo: data }, id).then(() => {
      if (key === 'spouse') {
        setSpouse(value);
      } else if (key === 'gender') {
        setGender(value);
      } else if (key === 'caringExperience') {
        setCaringExperience(value);
      }
    });
  };

  return (
    <motion.div
      className={styles.end}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h1>Qui êtes-vous ?</h1>
          <p>
          Avant d’avoir accès à votre restitution et aux résultats de l’étude,
          nous aurions besoin de connaître
          quelques infos supplémentaires vous concernant.
          Ces données sont anonymisées et ne seront pas conservées à la fin de l’étude.
          </p>
          <AnimatePresence mode='wait' >
            {step === 1 && (
              <motion.form
                key={'form-1'}
                initial={{ x: 0, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 20, opacity: 0 }}
                transition={{
                  duration: 0.4,
                }}
              >
                <div className={styles.question}>
                  <p>Avez-vous un conjoint ?</p>
                  <div className={styles.choices}>
                    <button
                      className={spouse ? styles.selected : ''}
                      type="button"
                      onClick={() => handleChangeChoice('spouse', true)}
                    >
                      Oui
                    </button>
                    <button
                      className={typeof spouse === 'boolean' && !spouse ? styles.selected : ''}
                      type="button"
                      onClick={() => handleChangeChoice('spouse', false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className={styles.question}>
                  <p>
                    Avez-vous une expérience d'aidant<br />
                    <span>(prendre soin d'un proche en difficulté) ?</span>
                  </p>
                  <div className={styles.choices}>
                    <button
                      className={caringExperience ? styles.selected : ''}
                      type="button"
                      onClick={() => handleChangeChoice('caringExperience', true)}
                    >
                      Oui
                    </button>
                    <button
                      className={typeof caringExperience === 'boolean' && !caringExperience ? styles.selected : ''}
                      type="button"
                      onClick={() => handleChangeChoice('caringExperience', false)}
                    >
                      Non
                    </button>
                  </div>
                </div>
                <div className={styles.question}>
                  <p>Quel est votre sexe ?</p>
                  <div className={styles.choices}>
                    <button
                      className={gender === 'man' ? styles.selected : ''}
                      type="button"
                      onClick={() => handleChangeChoice('gender', 'man')}
                    >
                      Homme
                    </button>
                    <button
                      className={gender === 'woman' ? styles.selected : ''}
                      type="button"
                      onClick={() => handleChangeChoice('gender', 'woman')}
                    >
                      Femme
                    </button>
                  </div>
                </div>
                <div className={styles.question}>
                  <p>Quel est votre niveau d'études ?</p>
                  <button type="button" className={styles.study} onClick={() => toggleModalStudy()}>
                    {studies || 'Aucun'}
                    <span>
                      <BiDownArrowAlt />
                    </span>
                  </button>
                </div>
              </motion.form>
            )}
            {step === 2 && (
              <motion.form
                key={'form-2'}
                initial={{ x: 0, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 20, opacity: 1 }}
                transition={{
                  duration: 0.4,
                }}
              >
                <div className={`${styles.question} ${styles.input}`}>
                  <p>
                    Vous souhaitez recevoir les résultats de l’étude&nbsp;?<br/>
                    Rentrez votre adresse mail :
                  </p>
                  <div className={styles.inputEmail}>
                    <input type='text' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    {typeof validEmail === 'boolean' && validEmail && (
                      <BsCheck size={30} color='#0288D1'/>
                    )}
                    {typeof validEmail === 'boolean' && !validEmail && (
                      <MdOutlineClose size={30} color='#fa8578'/>
                    )}
                  </div>
                </div>
                <div className={`${styles.question} ${styles.input}`}>
                  <p>
                    Vous-avez aimé cette expérience ? Donnez nous un avis&nbsp;!
                  </p>
                  <textarea rows={7} value={comment} onChange={(e) => setComment(e.target.value)}/>
                </div>
              </motion.form>
            )}
          </AnimatePresence>
          <div className={styles.next}>
            <button onClick={() => {
              navigate('/');
              localStorage.removeItem('id');
            }}>Passer cette étape</button>
            {step === 1
              && <button onClick={() => setStep(2)}>Suivant</button>
            }
            {step === 2
              && <button className={validEmail === false ? styles.disabled : ''} onClick={() => submit()}>Valider</button>
            }
          </div>
        </div>
        <div className={styles.visual}>
          <div></div>
        </div>
      </div>
    </motion.div>
  );
};

export default withModalStudy(End);
