import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { TypeAnimation } from 'react-type-animation';
import { BiRightArrowAlt } from 'react-icons/bi';
import { RiEmotionHappyLine } from 'react-icons/ri';
import AmazingEmoji from './images/amazing-emoji.svg';
import AngryEmoji from './images/angry-face.svg';
import HappyEmoji from './images/happy.svg';
import SadEmoji from './images/sad-emoji.svg';
import styles from './thread.module.scss';

type ThreadProps = {
  stepId: string,
  text: string,
  userResults: Record<string, string[]>,
  answers: AnswerProps[],
  answersType: string | null,
  handleChangeResults: (answer: string, type: string) => void,
  handleNextStep: (userTextAnswer: string) => void,
};

type ThreadHandler = {
  resetContent: () => void,
};

type ItemContentProps = {
  key: string,
  id?: string,
  index?: string,
  type: 'text' | 'answer' | 'limit' | 'answer-user',
  content: string,
  typewriting?: boolean,
  answersType?: string | null,
  customAnswer?: boolean,
  setContent?: (str: string) => void,
};

type AnswerProps = {
  id: string,
  text: string,
  customAnswer?: boolean,
};

const Thread:React.ForwardRefRenderFunction<ThreadHandler, ThreadProps> = ({
  stepId,
  text,
  answers,
  answersType,
  userResults,
  handleChangeResults,
  handleNextStep,
}, ref) => {
  const containerRef = useRef<any>();
  const contentRef = useRef<any>();
  const timerRef = useRef<any>();
  const [content, setContent] = useState<ItemContentProps[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useImperativeHandle(ref, () => ({
    resetContent() { setContent([]); },
  }));

  // * Handle content
  useEffect(() => {
    let udpatedContent = [...content];
    setInputValue('');

    if (text) {
      // const typewriting = udpatedContent.length !== 0;
      // setIsTyping(typewriting);

      const typewriting = false;
      udpatedContent.push({
        key: `text-${stepId}`,
        type: 'text',
        content: text,
        typewriting,
      });
    }

    if (answers.length) {
      answers.forEach((answer: AnswerProps, i: number) => {
        udpatedContent.push({
          id: answer.id,
          key: `answer-R${i + 1}-${answer.id}`,
          index: `R${i + 1}`,
          type: 'answer',
          content: answer.text,
          answersType,
          customAnswer: answer.customAnswer || false,
          setContent(str: string) {
            this.content = str;
          },
        });
      });
    }

    udpatedContent.push({
      key: `limit-${stepId}`,
      type: 'limit',
      content: '',
    });

    udpatedContent = udpatedContent
      .reduce((accumulator: ItemContentProps[], current: ItemContentProps) => {
        if (!accumulator.find((item: ItemContentProps) => item.key === current.key)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);

    const countLimit = udpatedContent.filter((d) => d.type === 'limit').length;
    if (countLimit === 3) {
      const firstLimit = udpatedContent.findIndex((d) => d.type === 'limit');
      udpatedContent = udpatedContent.slice(firstLimit + 1, udpatedContent.length);
    }

    setContent(udpatedContent);

    return () => {
      setContent([]);
    };
  }, [
    text,
    answers,
  ]);

  // Handle custom value
  useEffect(() => {
    const customAnswer = content.find((d) => d.customAnswer && d.id === stepId);
    if (customAnswer && typeof customAnswer.setContent === 'function') {
      customAnswer?.setContent(inputValue);
    }
  }, [stepId, content, inputValue]);

  const previousMessage = useCallback((index: number) => {
    const limit = (content.slice(0, content.length - 2) as any).findLastIndex((d: ItemContentProps) => d.type === 'limit');
    return index <= limit;
  }, [content]);

  // * Handle position for none typewriting message
  useEffect(() => {
    if (!contentRef.current) return;
    const limit = (content.slice(0, content.length - 2) as any).findLastIndex((d: ItemContentProps) => d.type === 'limit');
    if (limit < 0) return;
    const isTypewriting = content[limit + 1].typewriting;
    if (isTypewriting) return;
    const limitElement = contentRef.current.childNodes?.[limit];
    if (!limitElement) return;
    containerRef.current.scrollTo(0, limitElement.offsetTop - 30);
  }, [content]);

  // * Handle position for typewriting message
  useEffect(() => {
    if (isTyping) {
      let containerBound = containerRef.current.getBoundingClientRect();
      let contentBound = contentRef.current.getBoundingClientRect();
      if (contentBound.bottom > containerBound.bottom) {
        containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
      }
      timerRef.current = setInterval(() => {
        containerBound = containerRef.current.getBoundingClientRect();
        contentBound = contentRef.current.getBoundingClientRect();
        if (contentBound.bottom > containerBound.bottom) {
          containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
        }
      }, 100);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, [isTyping, contentRef, containerRef]);

  const getEmoji = useCallback((emoji: string) => {
    let icon;
    if (emoji === 'Excité') {
      icon = AmazingEmoji;
    } else if (emoji === 'Serein') {
      icon = HappyEmoji;
    } else if (emoji === 'Effrayé') {
      icon = SadEmoji;
    } else if (emoji === 'Énervé') {
      icon = AngryEmoji;
    }
    return (
      <>
        <img src={icon} alt={emoji} />
        <p>{emoji}</p>
      </>
    );
  }, []);

  // * Handle set input value if custom answer from user
  useEffect(() => {
    if (!userResults[stepId]
      || (answersType !== 'multiChoiceAndText' && answersType !== 'text')
    ) return;
    const isCustomAnswer = !answers.find((d: AnswerProps) => userResults[stepId].includes(d.text));
    if (isCustomAnswer) {
      setInputValue(userResults[stepId][0]);
    }
  }, [answersType, userResults]);

  const isStepCompleted = useMemo(() => {
    let completed = true;

    if (isTyping || answersType === 'uniqChoice' || answersType === 'emotes') completed = false;
    else if (answersType === 'text' && !inputValue) completed = false;
    else if (answersType === 'multiChoice' && (!userResults[stepId] || userResults[stepId]?.length === 0)) {
      completed = false;
    } else if (answersType === 'multiChoiceAndText' && !inputValue && (!userResults[stepId] || userResults[stepId]?.length === 0)) {
      completed = false;
    }
    return completed;
  }, [answersType, userResults, inputValue, isTyping, stepId]);

  const handlePressEnter = useCallback((e: React.KeyboardEvent) => {
    if (e.key !== 'Enter' || !isStepCompleted || (answersType !== 'text'
        && answersType !== 'multiChoiceAndText')) return;

    handleNextStep(inputValue);
  }, [isStepCompleted, answersType, inputValue]);

  const isSelected = useCallback((str: string, id?: string) => {
    let bool = true;
    if (!id) bool = false;
    else if (id && !(userResults[id] || []).includes(str)) {
      bool = false;
    }
    return bool;
  }, [userResults]);

  const getClassNameAnswer = (
    id: string,
    contentAnswer: string,
    type: string,
    customAnswer: boolean,
    index: number,
    contentType: string,
  ) => {
    let className = styles.containerAnswer;
    if (type === 'emotes') className += ` ${styles.emotes}`;
    if (contentType === 'limit') className += ` ${styles.limit}`;
    if (previousMessage(index)) className += ` ${styles.previous}`;
    if (!previousMessage(index) && customAnswer) className += ` ${styles.hide}`;
    if (isSelected(contentAnswer, id)) className += ` ${styles.selected}`;
    return className;
  };

  return (
    <div className={styles.thread}>
      <div
        ref={containerRef}
        className={styles.container}
        style={isTyping ? { overflow: 'hidden' } : {}}
      >
        <div className={styles.forceBottom} />
        <div className={styles.content} ref={contentRef}>
          {content.map((d: ItemContentProps, index: number) => (
            <div
              key={`${d.key}-${index}`}
              className={getClassNameAnswer(d.id || '', d.content, d.answersType || '', d.customAnswer || false, index, d.type)}
            >
              {(d.typewriting && d.type === 'text') && (
                <p
                  className={styles.typewriter}
                >
                  <TypeAnimation
                    key={d.key}
                    style={{ whiteSpace: 'pre-line', display: 'block' }}
                    sequence={[
                      d.content,
                      () => setIsTyping(false),
                    ]}
                    speed={{ type: 'keyStrokeDelayInMs', value: 20 }}
                    cursor={false}
                  />
                </p>
              )}
              {(!d.typewriting && d.type === 'text') && (
                <p
                  className={styles.message}
                >
                  {d.content}
                </p>
              )}
              {(d.type === 'answer') && (
                <>
                  {d.answersType === 'emotes' && (
                    <div
                      className={`${styles.answer} ${isSelected(d.content, d.id) ? styles.selected : ''}`}
                      onClick={() => {
                        handleChangeResults(d.content, d.index || '');
                      }}
                    >
                      {getEmoji(d.content)}
                    </div>
                  )}
                  {d.answersType === 'multiChoiceAndText' && (
                    <button
                      className={`${styles.answer} ${isSelected(d.content, d.id) ? styles.selected : ''}`}

                      onClick={() => {
                        if (inputValue) setInputValue('');
                        handleChangeResults(d.content, d.index || '');
                      }}
                    >
                      {d.content}
                    </button>
                  )}
                  {(d.answersType === 'text') && (
                    <button
                      className={`${styles.answer} ${previousMessage(index) ? styles.selected : ''}`}
                      onClick={() => {
                        handleChangeResults(d.content, d.index || '');
                      }}
                    >
                      {d.content}
                    </button>
                  )}
                  {d.answersType !== 'emotes' && d.answersType !== 'text' && d.answersType !== 'multiChoiceAndText' && (
                    <button
                      className={`${styles.answer} ${isSelected(d.content, d.id) ? styles.selected : ''}`}
                      onClick={() => {
                        handleChangeResults(d.content, d.index || '');
                      }}
                    >
                      {d.content}
                    </button>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        <div
          className={styles.nav}
          onKeyDown={handlePressEnter}
          onClick={() => {
            if (!isStepCompleted || answersType === 'text'
            || answersType === 'multiChoiceAndText') return;
            handleNextStep(inputValue);
          }}
        >
          <div>
            <div className={styles.icon}>
              <RiEmotionHappyLine />
            </div>
            <p>Suivant</p>
            {(answersType === 'text' || (answersType && /AndText/.test(answersType))) && (
              <input
                type='text'
                placeholder='Saisissez votre réponse'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            )}
          </div>
          <button
            className={!isStepCompleted ? styles.disabled : ''}
            onClick={() => {
              if (answersType !== 'text' && answersType !== 'multiChoiceAndText') return;
              handleNextStep(inputValue);
            }}
          >
            <BiRightArrowAlt />
          </button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Thread);
