import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

type Props = {
  children: React.ReactNode;
};

interface IPendingFunction {
  action: ((study: string) => void) | null;
}

const Modal = ({ children }: Props) => {
  const modalRoot = document.getElementById('modal');
  const [pendingFunction, setPendingFunction] = useState < IPendingFunction >({
    action: null,
  });

  const childrenWithProps = () => React.Children.map(children, (child: React.ReactNode) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        selectStudy: (action: () => void) => {
          setPendingFunction({
            action,
          });
        },
      });
    }
    return child;
  });

  const selectStudyAction = (study: string) => {
    if (study && pendingFunction.action) pendingFunction.action(study);
    setPendingFunction({
      action: null,
    });
  };

  return (
    <>
      {modalRoot && pendingFunction.action && (
        <>
          {createPortal(
            <>
              <div className={styles['overlay-modal']} onClick={() => {
                setPendingFunction({
                  action: null,
                });
              }}/>
              <div className={styles.modal}>
                <p>Quel est votre niveau d&apos;études ?</p>
                <div className={styles.containerButton}>
                  <button onClick={() => selectStudyAction('Aucun diplôme')}>
                    <span>Aucun diplôme</span>
                  </button>
                  <button onClick={() => selectStudyAction('CAP-BEP')}>
                    <span>CAP-BEP</span>
                  </button>
                  <button onClick={() => selectStudyAction('Bac')}>
                    <span>Bac</span>
                  </button>
                  <button onClick={() => selectStudyAction('Licence (Bac+3), Master (Bac +5) et plus')}>
                    <span>Licence (Bac+3), Master (Bac +5) et plus</span>
                  </button>
                </div>
              </div>
            </>,
            modalRoot,
          )}
        </>
      )}
      {childrenWithProps()}
    </>
  );
};

const withModalConfirm = (Component: React.ComponentType<any>) => ({
  ...props
}) => (
  <Modal>
    <Component {...props} />
  </Modal>
);

export default withModalConfirm;
