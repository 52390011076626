import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import questions from '../../assets/data/questions.json';
import { images, imagesAnswers } from '../../config/messageVisuals';
import styles from './visuals.module.scss';

const Visuals = (
  { className, userResults } : { className?: string, userResults: Record<string, string[]> },
) => {
  const [params] = useSearchParams();
  const [image, setImage] = useState('');

  useEffect(() => {
    let step: any = params.get('step');
    if (!step || Number.isNaN(step)) step = 1;
    else step = parseInt(step, 10);

    // __EMPTY_7: R1
    // __EMPTY_8: R2
    // __EMPTY_9: R3
    // __EMPTY_10: R4
    // __EMPTY_11: R5

    let imageName = null;
    if (step) {
      let i = step;
      while (i > 0) {
        const id = questions[i]?.__EMPTY_1;
        const answers = userResults[id] || [];
        const keyAnswers = [];
        const R1 = questions[i]?.__EMPTY_7;
        const R2 = questions[i]?.__EMPTY_8;
        const R3 = questions[i]?.__EMPTY_9;
        const R4 = questions[i]?.__EMPTY_10;
        const R5 = questions[i]?.__EMPTY_11;
        if (R1 && answers.includes(R1)) keyAnswers.push('R1');
        if (R2 && answers.includes(R2)) keyAnswers.push('R2');
        if (R3 && answers.includes(R3)) keyAnswers.push('R3');
        if (R4 && answers.includes(R4)) keyAnswers.push('R4');
        if (R5 && answers.includes(R5)) keyAnswers.push('R5');

        if (id) imageName = images[id];
        if (imagesAnswers[`${id}-${keyAnswers.join('-')}`]) {
          imageName = imagesAnswers[`${id}-${keyAnswers.join('-')}`];
        }
        if (imageName) break;
        i -= 1;
      }
    }

    if (imageName) setImage(imageName);
    else {
      setImage(images.default);
    }
  }, [params, userResults]);

  return (
    <div className={`${styles.visuals}${className ? ` ${className}` : ''}`}>
      <AnimatePresence mode='wait'>
        <motion.div
          key={image || 'empty'}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {image && (
            <img src={`${process.env.PUBLIC_URL}/images/${image}`} />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Visuals;
