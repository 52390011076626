const images: Record<string, string> = {
  default: 'garden.png',
  T5: 'house.png',
  Q1: 'livingroom.png',
  T7: 'house2.png',
  Q4: 'neighborhood.png',
  T9: 'residence.png',
  Q13: 'residence.png',
};

const imagesAnswers: Record<string, string> = {
  'Q9-R1': 'garden.png',
  'Q10-R1-R2': 'livingroom.png',
  'Q13-R3': 'neighborhood.png',
};

export {
  images,
  imagesAnswers,
};
