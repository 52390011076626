import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import images from '../../config/characterVisuals';
import styles from './character.module.scss';

const Character = ({ className } : { className?: string }) => {
  const [params] = useSearchParams();
  const [image, setImage] = useState('');

  useEffect(() => {
    let step: any = params.get('step');
    if (Number.isNaN(step)) step = null;
    else step = parseInt(step, 10);

    let imageName = null;
    if (step) {
      let i = step;
      while (i > 0) {
        imageName = images[i];
        if (imageName) break;
        i -= 1;
      }
    }

    if (imageName) setImage(imageName);
    else {
      setImage(images.default);
    }
  }, [params]);

  return (
    <div className={`${styles.character}${className ? ` ${className}` : ''}`}>
      <AnimatePresence mode='wait'>
        <motion.div
          key={image || 'empty'}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
          style={{ fontSize: 0 }}
        >
          {image && (
            <img src={`${process.env.PUBLIC_URL}/images/${image}`} />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Character;
